/* global algoliasearch instantsearch */

const searchClient = algoliasearch(
  'D6BX9MVNKI',
  '926381c7fa1db60145725e6a9062a16f'
);

const search = instantsearch({
  indexName: 'txtu_PROD',
  searchClient,
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
      item: `
<article>
<h2>{{#helpers.highlight}}{ "attribute": "Course Name" }{{/helpers.highlight}} - {{#helpers.highlight}}{ "attribute": "Course Number" }{{/helpers.highlight}}</h2>

  <h3>{{#helpers.highlight}}{ "attribute": "Instructor First Name" }{{/helpers.highlight}} {{#helpers.highlight}}{ "attribute": "Instructor Last Name" }{{/helpers.highlight}}</h3>

  <p>{{#helpers.highlight}}{ "attribute": "Brief Description" }{{/helpers.highlight}} [<strong>Size</strong> - {{#helpers.highlight}}{ "attribute": "Course Size" }{{/helpers.highlight}}]</p>

  <p><strong>{{#helpers.highlight}}{ "attribute": "Meeting Day" }{{/helpers.highlight}}</strong> - {{#helpers.highlight}}{ "attribute": "Start Time" }{{/helpers.highlight}} - {{#helpers.highlight}}{ "attribute": "End Time" }{{/helpers.highlight}}</p>

  <p><strong>CSU / Dept.</strong> - {{#helpers.highlight}}{ "attribute": "CSU" }{{/helpers.highlight}}, {{#helpers.highlight}}{ "attribute": "Department" }{{/helpers.highlight}}</p>

  <p><strong>Course ​Delivery ​Method</strong> - {{ Course Delivery Method }}</p>

  <p><strong>Observation ​Type</strong> - {{ Observation Type }}</p>

  {{#Content Keywords}}
    <p><strong>Content Keywords</strong> - {{#helpers.highlight}}{ "attribute": "Content Keywords" }{{/helpers.highlight}}</p>
  {{/Content Keywords}}

  {{#UGS Flags}}
  <p><strong>UGS Flags</strong> - {{#helpers.highlight}}{ "attribute": "UGS Flags" }{{/helpers.highlight}}</p>
  {{/UGS Flags}}

  {{#Other Filters}}
  <p><strong>Other Filters</strong> - {{#helpers.highlight}}{ "attribute": "Other Filters" }{{/helpers.highlight}}</p>
  {{/Other Filters}}

  {{#Course Full Status}}
    <p style="color:red;"><em>This course is full</em></p>
  {{/Course Full Status}}

  {{^Course Full Status}}
  <p><a class="btn btn-outline-primary btn-sm" role="button" href="{{ Registration Link }}" target="_blank">Register for this course &rarr;</a></p>
  {{/Course Full Status}}

  </article>
`,
    },
  }),
  instantsearch.widgets.stats({
    container: '#stats',
    templates: {
      text: `
        {{#hasNoResults}}No courses{{/hasNoResults}}
        {{#hasOneResult}}1 course{{/hasOneResult}}
        {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} courses{{/hasManyResults}}
        found in {{processingTimeMS}}ms
      `,
    },
  }),
  instantsearch.widgets.refinementList({
    container: '#refinement-list',
    attribute: 'Meeting Day',
    sortBy: ['name:desc'],
    limit: 6,
    showMore: false,
  }),
  instantsearch.widgets.refinementList({
    container: '#refinement-list-csu',
    attribute: 'CSU',
    sortBy: ['name:asc'],
    limit: 15,
    showMore: false,
  }),
  instantsearch.widgets.refinementList({
    container: '#other-filters',
    attribute: 'Other Filters',
    sortBy: ['name:asc'],
    limit: 12,
  }),
  instantsearch.widgets.clearRefinements({
    container: '#clear-refinements',
  }),
  instantsearch.widgets.pagination({
    container: '#pagination-2',
    totalPages: 8,
  }),
  /*
  instantsearch.widgets.toggleRefinement({
    container: '#course-not-full',
    attribute: 'Course Full Status',
    on: '',
    templates: {
      labelText: 'Course Not Full',
    },
  }),
  */
  instantsearch.widgets.pagination({
    container: '#pagination',
    totalPages: 8,
  }),
  instantsearch.widgets.hitsPerPage({
    container: '#hits-per-page',
    items: [
      { label: '8 hits per page', value: 8, default: true },
      { label: '16 hits per page', value: 16 },
    ],
  }),
]);

search.start();
